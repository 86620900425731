<template>
  <v-container fluid fill-height>
    <slot/>
  </v-container>
</template>

<script>
export default {
  name: 'AuthLayout',
};
</script>

<style lang="scss" scoped>

</style>
