<template>
  <AuthLayout>
    <v-row align="center" justify="center">
      <v-col md="4" sm="10">
        <v-form @submit.prevent="verifyUser">
          <v-card
            class="pa-10"
            elevation="2"
            outlined>
            <v-img src="@/assets/img/logo-Infinitec-Color.png"
                   class="mb-5"/>
            <v-card-text>
              <v-text-field
                prepend-icon="mdi-lock"
                name="password"
                :label="$t('commons.putPassword')"
                type="password"
                :error-messages="alert.message"
                v-model="userPassword"/>
            </v-card-text>
            <v-card-actions>
              <v-btn type="submit"
                     depressed>
                {{ $t('commons.signIn') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </AuthLayout>
</template>

<script>
import md5 from 'md5';
import AuthLayout from '../components/Layouts/AuthLayout.vue';

export default {
  name: 'LoginPage',
  components: { AuthLayout },

  data: () => ({
    userPassword: '',
    alert: {
      show: false,
      message: '',
    },
  }),

  methods: {
    verifyUser() {
      if (md5(this.userPassword) === 'e2123c2743d7a64f3203e4dae224dedc' || md5(this.userPassword) === '4ee9d19fa2c0c6dbd427d652002b6a02') {
        localStorage.setItem('SpqToken', md5(`${process.env.VUE_APP_SALT}${md5(this.userPassword)}`));
        this.$router.push({ name: 'dashboard' });
      } else {
        this.alert = {
          show: true,
          message: 'Złe hasło',
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
